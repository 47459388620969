import React from "react"
import ReactMarkdown from "react-markdown"
import { graphql } from "gatsby"

import { Layout } from "../components"

import styles from "./about.module.css"

export default ({ data }) => (
  <Layout>
    <div className={styles.content}>
      <ReactMarkdown
        escapeHtml={false}
        source={data.allContentfulPage.edges[0].node.text.text}
      />
    </div>
  </Layout>
)

export const query = graphql`
  query {
    allContentfulPage(
      filter: { contentful_id: { eq: "7p1MIA2P2tTZrKB2xdwiqY" } }
    ) {
      edges {
        node {
          title
          text {
            text
          }
        }
      }
    }
  }
`
